<template>
  <b-modal v-model="modalShow" size="lg" ok-title="Save" centered hide-footer>
    <template #modal-header>Product Serial Detail</template>
    <div v-if="isLoading" class="fix-height d-flex justify-content-center">
      <OtherLoading />
    </div>
    <div v-else>
      <b-row>
        <b-col class="col-12">
          <InputSelect
            title="Branch Name"
            name="type"
            isRequired
            valueField="id"
            textField="name"
            v-model="form.branch_id"
            :isValidate="$v.form.branch_id.$error"
            :v="$v.form.branch_id"
            :value="form.branch_id"
            :options="branchList"
          >
            <template v-slot:option-first>
              <b-form-select-option :value="null" disabled
                >-- Select Branch --
              </b-form-select-option>
            </template>
          </InputSelect>
        </b-col>
        <b-col class="col-6">
          <InputText
            v-model="form.serial"
            isRequired
            textFloat="Serial"
            placeholder="Serial"
            :isValidate="$v.form.serial.$error"
            :v="$v.form.serial"
            @onKeypress="onKeypressText"
            type="text"
          ></InputText>
        </b-col>
        <b-col class="col-6">
          <InputText
            v-model="form.price"
            isRequired
            textFloat="Price"
            placeholder="Price"
            :isValidate="$v.form.price.$error"
            :v="$v.form.branch_id"
            @onKeypress="onKeypressText"
            type="text"
          ></InputText>
        </b-col>
        <b-col class="col-6">
          <InputText
            v-model="form.weight"
            textFloat="Weight"
            placeholder="Weight"
            type="number"
          ></InputText>
        </b-col>
        <b-col class="col-12">
          <InputTextArea
            v-model="form.serial_detail"
            textFloat="Serial Detail"
            placeholder="Serial Deatil"
            type="text"
          ></InputTextArea>
        </b-col>
      </b-row>
    </div>
    <b-row class="mt-3">
      <b-col cols="6">
        <b-button class="btn-cancel btn-width" variant="dark" @click="hide"
          >Cancel</b-button
        >
      </b-col>
      <b-col cols="6" class="text-sm-right">
        <b-button
          class="main-color btn-save btn-width"
          @click="saveData()"
          :disabled="isLoading"
        >
          Confirm
        </b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>
<script>
import InputText from "@/components/inputs/InputText.vue";
import InputSelect from "@/components/inputs/InputSelect";
import InputTextArea from "@/components/inputs/InputTextArea";
import OtherLoading from "@/components/loading/OtherLoading";
import { required } from "vuelidate/lib/validators";

export default {
  components: {
    InputText,
    InputSelect,
    InputTextArea,
    OtherLoading,
  },
  props: {
    branchList: {
      required: true,
      type: Array,
    },
  },

  validations() {
    return {
      form: {
        branch_id: {
          required,
        },
        serial: {
          required,
        },
        price: {
          required,
        },
      },
    };
  },
  data() {
    return {
      isLoading: false,
      serial_id: "",
      modalShow: false,
      branch_short_name: "",
      form: {
        serial: null,
        serial_detail: "",
        price: null,
        weight: 0,
        product_id: parseInt(this.$route.params.id, 10),
        branch_id: null,
        id: 0,
      },
    };
  },
  methods: {
    async show(items) {
      this.isLoading = true;
      this.serial_id = items ? items.id : 0;
      this.modalShow = true;
      if (items) {
        const res = await this.axios(
          `/productmenu/get_serial_detail/${this.serial_id}`
        );
        this.form = res.data.detail;
      }
      this.isLoading = false;
    },

    async saveData() {
      this.$v.$touch();
      this.isLoading = true;
      if (!this.$v.$error) {
        if (this.serial_id === 0) {
          const res = await this.axios.post(
            `/productmenu/create_product_serial`,
            this.form
          );
          if (res.data.result == 1) {
            this.successAlert();
            this.$emit("success");
            this.hide();
          } else {
            this.errorAlert(res.data.message);
          }
        } else {
          const res = await this.axios.put(
            `/productmenu/update_product_serial`,
            this.form
          );
          if (res.data.result == 1) {
            this.successAlert();
            this.$emit("success");
            this.hide();
          } else {
            this.errorAlert(res.data.message);
          }
        }
      }
      this.isLoading = false;
    },
    hide() {
      this.form = {
        serial: "",
        serial_detail: "",
        price: null,
        branch_id: null,
        product_id: parseInt(this.$route.params.id, 10),
      };
      this.modalShow = false;
      this.$v.$reset();
    },
    onKeypressText(e) {
      if (
        (e.charCode >= 65 && e.charCode <= 90) ||
        (e.charCode >= 97 && e.charCode <= 122) ||
        (e.charCode >= 48 && e.charCode <= 57) ||
        e.charCode == 45
      ) {
        // return allowedEng;
      } else {
        e.preventDefault();
      }
    },
  },

  watch: {},
};
</script>
<style lang="scss">
.fix-height {
  height: 250px !important;
  .h-70 {
    height: unset !important;
  }
}
</style>
