<template>
  <div class="bg-white p-2 p-lg-3">
    <b-row class="row">
      <b-col class="col-md-6 col-10">
        <b-input-group class="mb-2">
          <b-form-input
            type="text"
            id="header-search-bar"
            class="search-bar"
            @keyup.enter="handleSearch"
            placeholder="Search Serial No."
            v-model="filter.search"
          >
          </b-form-input>
          <b-input-group-append is-text>
            <b-iconstack font-scale="2" type="submit" @click="handleSearch">
              <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
            </b-iconstack>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template v-slot:cell(price)="{ item }">
        <p class="m-0 price-text" v-if="item.price || item.price == 0">
          {{ item.price | numeral("0,0.00") }}
        </p>
        <p v-else class="m-0 price-text">-</p>
      </template>
      <template v-slot:cell(invoice_no)="{ item }">
        <router-link :to="'/report/transaction/' + item.transaction_id">
          <div class="name-link">{{ item.invoice_no }}</div>
        </router-link>
      </template>
      <template v-slot:cell(user_transaction_status_name)="{ item }">
        <div>
          <div v-if="item.deleted === 1" class="text-danger">Deleted</div>
          <div v-else-if="item.user_transaction_status_id === '1'">
            <span class="text-success">Complete </span>
            <span class="status-void" v-if="item.is_void === 1"
              >(Void/Returned)</span
            >
          </div>
          <div
            v-else-if="
              item.user_transaction_status_id === '2' ||
              item.user_transaction_status_id === '3'
            "
            class="status-void"
          >
            Void/Returned
          </div>
          <div
            v-else-if="item.user_transaction_status_id === '4'"
            class="text-danger"
          >
            {{ item.user_tranasction_status }}
          </div>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>

    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :filter="filter"
      :rows="rows"
      @pagination="pagination"
    />
  </div>
</template>
<script>
export default {
  data() {
    return {
      form: {
        email: "",
        file_name: "",
        excel_file: "",
        user_guid: "",
      },
      currnet_collection: "",
      isDisable: false,
      modalImport: false,
      isLoading: false,
      modalShow: false,
      id: this.$route.params.id,
      isBusy: false,
      items: [],
      fields: [
        {
          key: "serial",
          label: "Serial No.",
          thClass: "w-2",
        },
        {
          key: "branch_name",
          label: "Branch Name",
          thClass: "w-2",
        },
        {
          key: "price",
          label: "Price",
          thClass: "w-2",
        },
        { key: "invoice_no", label: "Invoice No.", thClass: "w-2" },
        {
          key: "user_transaction_status_name",
          label: "Transaction Status",
          thClass: "w-1",
        },
      ],
      filter: {
        search: "",
        page: 1,
        take: 10,
        product_id: Number(this.$route.params.id),
      },
      rows: 0,
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
    };
  },
  created() {
    this.getHistory();
  },
  methods: {
    async getHistory() {
      this.isBusy = true;
      const res = await this.axios.post(
        `/productmenu/get_product_serial_history/${this.id}`,
        this.filter
      );
      this.rows = res.data.detail.count;
      this.items = res.data.detail.data;
      this.isBusy = false;
    },

    handleSearch() {
      this.filter.page = 1;
      this.getHistory();
    },
    pagination(val) {
      this.filter.page = val;
      this.getHistory();
    },
    handleChangeTake() {
      this.filter.page = 1;
      this.getHistory();
    },
  },
};
</script>
<style lang="scss" scoped>
.dropdown-collection {
  width: 50% !important;
}

.icon-size {
  font-size: 20px;
  color: #575757;
}
.status-void {
  color: var(--primary-color);
}
</style>
